<template>
    <section class="flex flex-col gap-4">
        <MyAccountLegend :help-link="helpLink">
            {{ $t("AdditionalFeatures.Header") }}
        </MyAccountLegend>

        <p v-if="enabledFeatures.length" v-html="$t('AdditionalFeatures.Description')" />
        <p v-else v-html="$t('AdditionalFeatures.NoEnabledFeaturesDescription')" />

        <div v-if="enabledFeatures.length">
            <h3 class="p-2 border-b-2">{{ $t("AdditionalFeatures.EnabledFeatures") }}</h3>
            <div v-for="feature in enabledFeatures" :key="feature.Link" class="p-2 border-b even:bg-gray-50">
                <a :href="feature.Link">{{ feature.Name }}</a>
                <p v-if="feature.Description" class="m-0 text-sm" v-html="feature.Description" />
            </div>
        </div>

        <div v-if="disabledFeatures.length">
            <h3 class="p-2 border-b-2">{{ $t("AdditionalFeatures.FeaturesYouMayRequest") }}</h3>
            <div v-for="feature in disabledFeatures" :key="feature.RequestUrl" class="p-2 border-b even:bg-gray-50">
                <a :href="feature.RequestUrl">{{ feature.Name }}</a>
                <p v-if="feature.Description" class="m-0 text-sm" v-html="feature.Description" />
            </div>
        </div>

        <p v-html="$t('AdditionalFeatures.Footer', [userRequestsEmail])" />
    </section>
</template>

<script setup lang="ts">
const { data: userFeatures } = await useFetchLocaleApi<SiteFeature[]>("api/account/user-features");

const helpLink = PageHelpers.GetDocsUrl("my-account/");
const userRequestsEmail = "user-requests@trustedparts.com";
const enabledFeatures = computed(() => (userFeatures.value ? userFeatures.value.filter((x) => x.Enabled) : []));
const disabledFeatures = computed(() => (userFeatures.value ? userFeatures.value.filter((x) => !x.Enabled) : []));
</script>
